import React from "react";
import Layout from "../components/layout";
import { InternalLink } from "../components/sitelinks";
import SEO from "../components/seo";
import {
    MainContainer,
    BoxContainer,
    BoxHeading,
    SubHeading,
    BoxCopy
} from "../components/simplepagelayout";


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <MainContainer>
        <BoxContainer>
        <BoxHeading>
           404 Not Found
        </BoxHeading>

        <BoxCopy>
        <p>
        Sorry, we couldn't find that page. Try going to our <InternalLink to="/">home page</InternalLink>, or <InternalLink to="/software/">Downloads page</InternalLink>, or select another destination from the nav bar.
        </p>
        </BoxCopy>
        </BoxContainer>

    </MainContainer>
  </Layout>
)

export default NotFoundPage;
